$(function (){
  
  
  $('.tyosa-modal').iziModal({
    width: 600,
    padding: '40px',
    overlayColor: 'rgba(0, 0, 0, 0.8)',
    transitionIn: 'bounceInDown',
    transitionOut: 'bounceOutDown',
    zindex: '10000'
  });
  
});